import { FC, ReactElement, useState, useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';

// const packageJson = require('../../package.json');

export const Footer = (): ReactElement => {
	const border = '0px solid';

	return (
		<Box
			sx={{
				position: "fixed",
				bottom: 0,
				width: "100%",
				height: '45px',
				backgroundColor: "#ffffff",
				color: "#000000",
				padding: "5px",
				// fontSize: "100px",
			}}
		>
			<Container maxWidth='xl'>
				<Grid container display={'flex'} alignItems="center" direction="row" >
					<Grid item xs={12} display={'flex'} alignItems="center" border={{ border }}>
						<span style={{ display: 'inline-flex', alignItems: 'center' }}>
							Tech Gap LLC&trade; <BusinessOutlinedIcon style={{ paddingLeft: '15px', paddingRight: '10px' }} /> PO BOX 64538 &ndash; Tucson, AZ 85718
						</span>
					</Grid>
				</Grid>
			</Container>
		</Box>

	);
};

export default Footer;
