import React from 'react';
import logoTGINT from './assets/images/logo-TGINT.png';
import logoTG from './assets/images/logo-TG.png';
import logoTGI from './assets/images/logo-TGI.png';
import logoTGS from './assets/images/logo-TGS.png';
import logoTGG from './assets/images/logo-TGG.png';
import DeleteIcon from '@mui/icons-material/Delete';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import TtyOutlinedIcon from '@mui/icons-material/TtyOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import './App.css';

import { Box, Button, Grid } from '@mui/material';
import Footer from './Footer';

function App() {
  const borderLeftRight = "1px solid red";
  // const imageBacgroundColor = "#eee";
  const imageBacgroundColor = "#fff";
  const minHeight = '135px';
  const noBorder = 'none';
  return (
    <div className="App">
      <div className="App-header">
        <Box justifyContent='center' >
          <Grid container justifyContent='center' justifyItems={'center'} flexDirection={'column'}>
            <Grid container xs={12} justifyContent='center' flexDirection={'row'} paddingTop={'20px'} paddingBottom={'0px'} borderTop={borderLeftRight} borderBottom={borderLeftRight}>
              <Grid item xs={12} md={12}>
                <div style={{ minHeight: minHeight }}>
                  <img src={logoTG} style={{ backgroundColor: imageBacgroundColor, paddingTop: '12px', objectFit: 'contain', width: '30%', maxHeight: '130px' }} />
                </div>
                <p className='centered'>
                  {/* Tech Gap LLC&trade; is a management services company that owns Tech Gap Italia Srl.<br /> */}
                  Tech Gap LLC&trade; is a worldwide provider of information technology services, providing software design and development services to businesses and organizations in various sectors, from manufacturing to finance, from construction to healthcare, and from agri-food to textiles.  Tech Gap’s capabilities include providing you with enterprise software, system software, utility software, application software, middleware, programming software, mobile applications and/or web-based software.<br />
                  <br />
                  If you are interested in our services or in partening with us, please reach out to us<br />
                  <br />
                  By e.mail:&nbsp;&nbsp;info@techgapgroup.com&nbsp;&nbsp;
                  <Button variant="outlined"
                    size="large"
                    style={{ borderColor: '#fff', color: '#fff' }}
                    rel="noopener noreferrer"
                    href={`mailto:info@techgapgroup.com`}
                    startIcon={<MailOutlinedIcon />}
                    target='_blank'>
                    info@techgapgroup.com
                  </Button><br />
                  <br />
                  By phone:&nbsp;&nbsp;+15205293176&nbsp;&nbsp;
                  <Button variant="outlined"
                    size="large"
                    style={{ borderColor: '#fff', color: '#fff' }}
                    rel="noopener noreferrer"
                    href={`tel:+15205293176`}
                    startIcon={<LocalPhoneOutlinedIcon />}
                  >
                    +1 (520) 529-3176
                  </Button><br />
                  <br />
                  <span style={{ display: 'inline-flex', alignItems: 'center' }}>Mailing address:&nbsp;&nbsp;
                    <BusinessOutlinedIcon style={{ paddingRight: '10px' }} /> PO BOX 64538 &ndash;&nbsp; <b>Tucson, AZ 85718</b>
                  </span>
                  <br /><br />
                  Tech Gap's services are provided through its licensees <b>Tech Gap Italia Srl</b>, <b>Tech Gap International LLC</b>, and <b>Tech Gap Solutions Srl</b>
                </p>
              </Grid>
            </Grid>
            <Grid paddingTop={'20px'} paddingBottom={'35px'} container xs={12} borderLeft={noBorder} borderRight={noBorder} borderBottom={borderLeftRight} justifyContent='center'>
              <Grid item xs={12} md={6}>
                <div style={{ backgroundColor: imageBacgroundColor, minHeight: minHeight }}>
                  <img src={logoTGG} style={{ objectFit: 'contain', width: '80%', maxHeight: '130px' }} />
                </div>
                <p>
                  Tech Gap Group LLC&trade; is a management services company. Tech Gap group LLC&trade; is shareholder of Tech Gap Italia Srl.
                  <br />
                </p>
              </Grid>
              <Grid item xs={12} md={6} >
                <div style={{ backgroundColor: imageBacgroundColor, minHeight: minHeight }} >
                  <img src={logoTGI} style={{ paddingTop: '12px', objectFit: 'contain', width: '80%', maxHeight: '110px' }} />
                </div>
                <p>
                  Tech Gap Italia is based in Milan, Italy, and has been operating in the Information Technology industry for over 15 years, with a strong focus on internationalization. Tech Gap Italia develops IT projects for clients worldwide and proprietary solutions for Team Organization & Management.
                </p>
                <Button variant="outlined"
                  size="large"
                  style={{ borderColor: '#fff', color: '#fff' }}
                  rel="noopener noreferrer"
                  href={`https://www.techgap.it/`}
                  target='_blank'>
                  DISCOVER MORE
                </Button>
              </Grid>
            </Grid>
            {/* </Grid> */}
            <Grid paddingTop={'20px'} container xs={12} borderLeft={noBorder} borderRight={noBorder} borderBottom={borderLeftRight} justifyContent='center'>
              <Grid item xs={12} md={6} paddingBottom={'50px'}>
                <div style={{ backgroundColor: imageBacgroundColor, minHeight: minHeight }}>
                  <img src={logoTGINT} style={{ objectFit: 'contain', width: '80%', maxHeight: '130px' }} />
                </div>
                <p>
                  Tech Gap International LLC  is a Software Factory based in US. The TGInt LLC carry out internationally IT projects, with colleagues in Romania and Italy for clients around the world.
                </p>
                <Button variant="outlined"
                  size="large"
                  style={{ borderColor: '#fff', color: '#fff' }}
                  rel="noopener noreferrer"
                  href={`https://www.techgapint.com/`}
                  target='_blank'>
                  DISCOVER MORE
                </Button>
              </Grid>
              <Grid item xs={12} md={6} paddingBottom={'50px'}>
                <div style={{ backgroundColor: imageBacgroundColor, minHeight: minHeight }}>
                  <img src={logoTGS} style={{ paddingTop: '7px', objectFit: 'contain', width: '80%', maxHeight: '105px' }} />
                </div>
                <p>
                  Tech Gap Solution is a Software Factory based in Romania. It is a focused company of passionate software developers and engineers, with an emphasis on tailored client solutions that push the boundaries of technology and innovation.
                </p>
                <Button variant="outlined"
                  size="large"
                  style={{ borderColor: '#fff', color: '#fff' }}
                  rel="noopener noreferrer"
                  href={`https://www.techgapsolutions.ro/`}
                  target='_blank'>
                  DISCOVER MORE
                </Button>
              </Grid>
            </Grid>
            <Grid container xs={12} justifyContent='center' flexDirection={'row'} paddingTop={'20px'} paddingBottom={'20px'} borderTop={noBorder} borderBottom={borderLeftRight}>
              <Grid item xs={12} md={12}>
                <b>TECH GAP</b> and the <b>TECH GAP logo</b> are trademarks of <b>Tech Gap, LLC</b> and are used under license by <b>Tech Gap Italia Srl</b>, <b>Tech Gap International LLC</b>, and <b>Tech Gap Solutions Srl</b>. &copy;2023 All Rights Reserved.
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
      <Footer />
    </div>
  );
}

export default App;
